<template>
  <land-section
    id="home_intro"
    :color="classify.backColor || 'transparent'"
    :space="classify.space || 40"
  >
    <div class="d-flex flex-column align-center">
      <land-subheading
        v-if="header.title"
        :align="header.align || 'center'"
        :title="header.title"
        class="text-uppercase"
        space="1"
      />
      <land-subheading
        v-if="header.minor"
        :align="header.align || 'center'"
        :title="header.minor"
        class="text-uppercase"
        space="2"
      />

      <land-divider
        class="mx-auto"
        width="28"
        color="primary"
        space="2"
      />

      <land-body
        v-if="header.subtitle"
        class="mx-auto"
        max-width="700"
        space="1"
      >
        {{ header.subtitle }}
      </land-body>

      <v-btn
        v-if="button.text"
        depressed
        :color="button.backColor || 'primary'"
        :class="[button.foreColor ? `${button.foreColor}--text` : 'white--text', 'mt-4']"
        :width="btnWidth"
        @click="onClick"
      >
        {{ button.text }}
      </v-btn>
    </div>

    <div class="co-w-full co-h24" />
    <div id="imgs-area">
      <div
        v-for="(item, ind) in items"
        :key="ind"
        class="img-box"
        :class="[`img-box${ind + 1}`]"
      >
        <img
          class="img-content"
          :src="item"
        >
      </div>
    </div>

    <!-- <div id="imgs-banner">
      <div class="img-list img-wrapper">
        <div
          v-for="(item, ind) in items"
          :key="ind"
          class="img-box"
        >
          <img :src="item">
        </div>
      </div>
    </div> -->

    <!-- <div class="co-w-full co-h24" /> -->
    <!-- <div class="land-swiper">
      <el-carousel
        v-if="smAndDown"
        :interval="6000"
        height="300px"
      >
        <el-carousel-item
          v-for="(item, ind) in items"
          :key="ind"
        >
          <el-image
            :src="item"
            fit="fill"
          />
        </el-carousel-item>
      </el-carousel>
      <el-carousel
        v-else
        :interval="6000"
        type="card"
        height="460px"
        style="overflow-x: unset;"
      >
        <land-carousel-item
          v-for="(item, ind) in items"
          :key="ind"
        >
          <el-image
            :src="item"
            fit="fill"
          />
        </land-carousel-item>
      </el-carousel>
    </div> -->
  </land-section>
</template>

<script>

  import api from '@/api/co.api'
  import web from '@/api/web/co.web'

  export default {
    name: 'IntroBanner',
    data () {
      return {
        classify: {},
        header: {
          title: '我遇见最美的光',
          minor: '是医务工作者的人文社交平台',
          subtitle: '在这里，我们相信光、遇见光、成为光、散发光！'
        },
        button: {
          text: '探索更多'
        },
        inPoints: {
          xs: 200,
          sm: 300,
          md: 500,
          lg: 650,
          xl: 650
        },
        inHeight: 500,
        cover: 'https://oss.landcoo.com/yujian/1645430064113913856.png',
        items: [],
      }
    },
    computed: {
      wdHeight () {
        const name = this.$vuetify.breakpoint.name
        const pts = this.classify.points || {}
        const ht = pts[name] || this.inPoints[name]
        return ht
      },
      btnXLarge () {
        if (this.$vuetify.breakpoint.smAndDown) {
          return false
        } else {
          return true
        }
      },
      btnWidth () {
        if (this.$vuetify.breakpoint.smAndDown) {
          return 120
        } else {
          return 140
        }
      },
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      }
    },
    created () {
      this.getData()
    },
    methods: {
      onClick (ev) {
        api.page.navigate(this, 'hot')
      },
      getData () {
        const me = this
        let params = null
        me.items = []
        const executed = function (res) {
          if (res.status) {
            for (const i in params.items) {
              const item = params.items[i]
              me.items.push(item.cover)
            }
          }
        }
        params = web.banner.getParams({
          type: web.comm.BannerTypes.HOME_MINOR,
          currentPage: '1',
          pageSize: '5',
          executed
        })

        params.reload = true
        api.httpx.getItems(params)
      },
    }
  }
</script>

<style lang="scss">

.land-swiper {
  width: 100vw;
  height: 100%;
}

:root {
  --post-spacing: 0;
  --post-size: 20vw;
  --mask-size: 100vw;
}

#imgs-area {
  width: 100vw;
  height: 25vw;
  display: flex;
  flex-direction: row;
  perspective: 1500px;

  .img-box {
    position:relative;
  }

  .img-box1 {
    width: 10vw;
    padding-right: 20px;
    transform-origin: left;
    transform: rotateY(50deg);
  }

  .img-box2 {
    width: 22vw;
    transform-origin: left;
    transform: rotateY(40deg);
  }

  .img-box3 {
    width: 36vw;
  }

  .img-box4 {
    width: 22vw;
    transform-origin: right;
    transform: rotateY(-40deg);
  }

  .img-box5 {
    width: 10vw;
    padding-left: 20px;
    transform-origin: right;
    transform: rotateY(-50deg);
  }

  .img-content {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
}

#imgs-banner {
  overflow: hidden;
  position: relative;
  width: 100vw;
  // height: calc(var(--post-size) / 0.57);
  height: var(--post-size);
  mask: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNDQwIiBoZWlnaHQ9IjUwMCIgdmlld0JveD0iMCAwIDE0NDAgNTAwIiBpZD0iaiI+CiAgPHBhdGggZmlsbD0icmdiKDIwMCwyMDAsMjAwKSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAwczI3NS4wNCAxMDAgNzIwIDEwMFMxNDQwIDAgMTQ0MCAwdjUwMHMtMjc1LjA0LTEwMC03MjAtMTAwUzAgNTAwIDAgNTAwVjB6Ii8+Cjwvc3ZnPgo=);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: var(--mask-size);
  position: relative;

  .img-wrapper {
    display: flex;
    position: absolute;
    width: 100%;
    float: left;
    //height: calc(var(--post-size) / 0.57);
    height: var(--post-size);
    animation: admission 1.5s;

    .img-box {
      height: 100%;
      display: inline-block;
      margin-right: var(--post-spacing);
      position: relative;

      img {
        width: var(--post-size);
        height: 100%;
        object-position: center;
        object-fit: cover;
        -webkit-box-reflect: below 0px;
      }
    }
  }
}

</style>
